<template>
    <header class="gridx">
      <vs-navbar
        v-model="indexActive"
        :color="topbarColor"
        class="topnavbar"
        text-color="rgba(255,255,255,0.7)"
        active-text-color="rgba(255,255,255,1)"
      >
        <!---
        Template logo
        -->
        <div slot="title" class="themelogo">
          <img :src="logo" height="50" />
          <!-- <span class="logo-text ml-1" v-if="title">{{ title }}</span> -->
        </div>

        <vs-spacer></vs-spacer>
      
        <!---
        Craete new dd
        -->
        <vs-dropdown vs-trigger-click class="cursor-pointer ml-1 mr-md-3">
          <a class="small-icon text-white-dark" href="#">
            {{ getCurrentLanguage.lang }}
            <vs-icon icon="expand_more"></vs-icon>
          </a>
          <vs-dropdown-menu class="topbar-dd">
            <vs-dropdown-item @click="changeLanguage('en')">English</vs-dropdown-item>
            <vs-dropdown-item @click="changeLanguage('fr')">Français</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

      </vs-navbar>
    </header>
</template>

<script>

export default {
  name: "Header",
  props: {
    logo: {
      // type: Boolean,
      required: true
    }
  },
  data: () => ({
    title: "LOKMI",

    //topbarColor: "#fff",
    indexActive: 0,
    showToggle: false,
  }),
  computed: {
    topbarColor: function() {
    return this.$store.state.topbarColor;
    },
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { lang: "English" };
      else if (locale == "fr") return { lang: "Français" };
      else if (locale == "ch") return { lang: "Chinese" };
      else if (locale == "gr") return { lang: "German" };
      return this.locale;
    },
  },
  components: {
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
  }
};
</script>

<style lang="scss">

</style>

